<template>
  <div
    class="vertical-layout training custom-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden || !User.isAuth() || isHideMenu ? '1-column' : null"
  >
    <!-- Navbar -->
<!--    <b-navbar-->
<!--      :toggleable="false"-->
<!--      :variant="navbarBackgroundColor"-->
<!--      class="header-navbar navbar navbar-shadow align-items-center"-->
<!--      :class="[navbarTypeClass]"-->
<!--    >-->
<!--      <slot-->
<!--        name="navbar"-->
<!--        :toggleVerticalMenuActive="toggleVerticalMenuActive"-->
<!--        :navbarBackgroundColor="navbarBackgroundColor"-->
<!--        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"-->
<!--      >-->
<!--        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />-->
<!--      </slot>-->
<!--    </b-navbar>-->
    <Header/>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden && User.isAuth() && !isHideMenu"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->

    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <showAt breakpoint="mediumAndBelow">
        <bottom-nav-menu v-show="User.isAuth()" />
    </showAt>

    <b-modal
        v-model="showCurrencyModal"
        centered
        :title="$t('modals.currency-conversion')"
        ok-only
        ok-title="Готово"
        @hide="pickerWillHide"
        modal-class="with-no-paddings"
    >

        <b-list-group flush style="max-height: 255px; overflow-y: scroll;">
            <b-list-group-item v-for="currency in Blockchain.tickers.listOf" @click="pickCurrency(currency)" :key="currency.ticker" :active=" currency.ticker === User.currency " style="cursor: pointer;">
                <div class="d-flex justify-content-between">
                    <div>
                        {{ currency.ticker }}
                    </div>
                    <div>
                        {{ currency.value.formatMoney(2,' ', ' ') }}
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>

    </b-modal>

    <Footer/>

    <!-- Footer -->
<!--    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>-->
    <!-- /Footer -->

    <slot name="customizer" />


    <div
        :style="{
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 1000,
            transitionProperty: 'all',
            transitionDuration: '.22s',
            left: isVerticalMenuCollapsed ? '80px' : (User.isAuth() ? '224px' : '0px') ,
            transitionProperty: 'all',
            transitionDuration: '.35s',
            transform: cart.length > 0 && $route.name !== 'user-miners-checkout' ? 'translateY(0px)' : 'translateY(310px)'

        }">
        <cart-view :isVerticalMenuCollapsed="isVerticalMenuCollapsed" />
    </div>

  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import BottomNavMenu from './components/bottom-nav-menu/BottomNavMenu.vue'
import Footer from "@/views/Main/main-design-v2/components/Footer"
import Header from "@/views/Main/main-design-v2/components/Header"

import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

import Blockchain from "@/modules/blockchain"
import User from "@/modules/user/"
import { showAt, hideAt } from 'vue-breakpoints'

import CartView from "@/components/CartView"
import miners from "@/modules/contents/miners"

export default {

   data() {
        return {
            Blockchain,
            User,
            miners,
            showCurrencyModal: false,
            canGoBack: false,
        }
   },

   methods: {
        pickCurrency( currency ) {
            User.currency = currency.ticker;
        },
        pickerWillHide() {
            this.$router.replace( this.$route.path );
        },
        isHideMenu() {
          switch(this.currentRouteName()){
            case 'payment-success':
              return true;
            case 'payment-error':
              return true;
            case 'payment-pending':
              return true;
            default:
              return false;
          }
        }
   },

   created() {
        document.body.style.overflowY = 'scroll';
    },

    destroyed() {
        document.body.style.overflowY = '';
    },

   watch: {
        "$route.query.act" : function( action ) {

            switch(action) {
              case 'currency':
                  this.showCurrencyModal = true;
                  return;
            }

            if( this.showCurrencyModal ) {
                this.showCurrencyModal = false;
            }

        },


   },

  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BottomNavMenu,
    BNavbar,
    Footer,
    Header,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    showAt,
    CartView
  },
  mixins: [mixinVerticalLayout],
  computed: {

    cart() {

        return Object.values( miners.cart );

    },


    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },

    currentRouteName() {
        return this.$route.name;
    }

  },



  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      isMouseHovered,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })
//
//    if( User.isAuth() === false ){
//        layoutClasses.push("unauthorized");
//    }

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,
      isMouseHovered,
      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss">

body {
        // overflow-y: scroll;
    }

@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
