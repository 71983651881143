<template>
    <div class="footer" style="height: auto;">
            <b-row>
                <b-col md="6" lg="3" class="footer-informer">
                    <p id="phone_roistat">+7 (800) 775-85-98</p>
                    <span class="text-muted">
                        Бесплатный звонок по России
                    </span>
                </b-col>
                <b-col md="6" lg="3" class="footer-informer">
                    <p>info@qubity.io</p>
                    <span class="text-muted">
                        По вопросам и предложениям
                    </span>
                </b-col>
                <b-col md="6" lg="3" class="footer-informer">
                    <div @click="showModal('callback')" class="d-flex justify-content-start m-0" style="cursor: pointer">
                        <b-img fluid src="~@/assets/images/site/icons/q-telegram.svg" style="min-width: 40px; height: 40px; width: 40px; align-self: center;" />
                        <div style="margin-left: 12px;">
                            <p>Получить консультацию</p>
                            <span class="text-muted">
                                Ответим на все вопросы
                            </span>
                        </div>
                    </div>
                </b-col>
                <b-col md="6" lg="3" class="footer-informer d-flex align-items-center">
                    <b-img fluid src="~@/assets/images/site/icons/q-location.svg" style="min-width: 40px; height: 40px; width: 40px; " />
                    <div style="margin-left: 12px;">
                        <span>
                            г. Москва, ул. Бутлерова, д. 17, БЦ «Нео Гео», линия F, 7 этаж
                        </span>
                    </div>
                </b-col>
            </b-row>
            <hr class="dark" style="margin-bottom: 16px;">
            <div>
                <p style="font-size: 16px; line-height: 24px; font-weight: 300; padding-bottom: 16px; border-bottom: 1px solid rgba(169, 169, 169, 0.2);">
                    Курсы криптовалют
                    <span class="text-muted">
                        <!--(на 25.04.2021)-->
                    </span>
                </p>
                <b-row>
                    <b-col v-for="(item, index) in utils.listing.items" :key="index" md="6" lg="4">
                        
                        <showAt breakpoint='mediumAndAbove'>
                            <b-row class="currency-market-price">
                                <b-col cols="3" class="d-flex">
                                    <div :class="`q-icon q-${item.symbol.toLowerCase()}-icon`" style="margin-right: 12px;"></div>
                                    <div class="currency-symbol">{{ item.symbol }}</div>
                                </b-col>
                                <b-col cols="3">
                                    <div class="currency-name">
                                        {{ item.name }}
                                    </div>
                                </b-col>
                                <b-col cols="2">
                                    <div class="currency-percent">
                                        <span v-if="item.market_price_percent_change_24h > 0" class="text-success">{{ item.market_price_percent_change_24h.toFixed(2) }}%</span>
                                        <span v-else class="text-danger">{{ item.market_price_percent_change_24h.toFixed(2) }}%</span>
                                    </div>
                                </b-col>
                                <b-col cols="4">
                                    <div class="currency-price">
                                        {{ item.market_price_usd.formatMoney(2," "," ") }} $
                                    </div>
                                </b-col>
                            </b-row>
                        </showAt>
                        
                        <showAt breakpoint='small'>
                            <b-row class="currency-market-price">
                                <b-col cols="4" class="d-flex">
                                    <div :class="`q-icon q-${item.symbol.toLowerCase()}-icon`" style="margin-right: 12px;"></div>
                                    <div class="currency-symbol">{{ item.symbol }}</div>
                                </b-col>
                               
                                <b-col cols="4">
                                    <div class="currency-percent">
                                        <span v-if="item.market_price_percent_change_24h > 0" class="text-success">{{ item.market_price_percent_change_24h.toFixed(2) }}%</span>
                                        <span v-else class="text-danger">{{ item.market_price_percent_change_24h.toFixed(2) }}%</span>
                                    </div>
                                </b-col>
                                <b-col cols="4">
                                    <div class="currency-price">
                                        {{ item.market_price_usd.formatMoney(2," "," ") }} $
                                    </div>
                                </b-col>
                            </b-row>
                        </showAt>
                      
                    </b-col>
                </b-row>
            </div>
            <hr class="dark" style="margin-bottom: 16px;">
            <showAt breakpoint="mediumAndAbove">
                <div class="d-flex justify-content-between">
                    <span style="font-weight: 300;">© 2022 «Qubity Inc OÜ»</span>
                    <div class="d-flex justify-content-between ">
                         <b-link :to="{ name: 'page-policy' }">Политика конфиденциальности</b-link>
                         <b-link :to="{ name: 'page-public-offer' }">Публичная оферта</b-link>
                    </div>
                </div>
            </showAt>
            <showAt breakpoint="small">
                <div class="w-100">
                    
                    <div class="d-flex flex-column justify-content-center">
                        <span class="text-center mb-1" style="font-weight: 300;">© 2022 «Qubity Inc OÜ»</span>
                        <div class="d-flex justify-content-center ">
                             <b-link :to="{ name: 'page-policy' }">Политика</b-link>
                             <b-link :to="{ name: 'page-public-offer' }">Оферта</b-link>
                        </div>
                    </div>
                </div>
            </showAt>

            <b-modal
                id="callback-modal"
                v-model="modalsVisibility.callback"
                title="Оставьте заявку и получите консультацию нашего менеджера"
                centered
                hide-footer
            >
                <callback-modal-content :selected="'telegram'"/>
            </b-modal>

        </div>
</template>

<script>

    import utils from "@/modules/utils"
    import CallbackModalContent from "@/views/Main/v1/modals/callback";

    export default {

        data() {
            return {
                utils,
                modalsVisibility: {
                    callback: false,
                },
            }
        },
        methods: {
            showModal(action) {
                Object.keys(this.modalsVisibility).forEach((key) => {
                    this.modalsVisibility[key] = false;
                });

                if (this.modalsVisibility.hasOwnProperty(action)) {
                    this.modalsVisibility[action] = true;
                }
            },
            closeModal(action) {
                Object.keys(this.modalsVisibility).forEach((key) => {
                    this.modalsVisibility[key] = false;
                });
            },
        },
        components: {
            CallbackModalContent
        },
        watch: {

        },
        mounted() {
            if( utils.listing.items.length === 0 ) {
                utils.listing.get();
            }
        }

    }

</script>