
export default [
//    {
//        title: 'Главная',
//        route: 'admin-dashboard',
//        icon: 'home',
//        group: "admin"
//    },
    {
        title: 'Пользователи',
        route: 'admin-users-list',
        icon: 'users',
        group: "admin"
    },
    {
        title: 'Контракты',
        route: 'admin-contracts-list',
        icon: 'documents',
        group: "admin"
    },
    {
        title: 'Майнеры',
        route: 'admin-miners-list',
        icon: 'mining'
    },
    {
        title: 'Кошельки',
        route: 'admin-finance',
        icon : 'wallet',
    },
    {
        title: 'Тикеты',
        route: 'admin-tickets',
        icon: 'documents',
        group: "admin"
    },
    // {
    //     title: "Новости",
    //     icon: 'news',
    //     route: 'admin-news-list',
    //     group: "admin"
    // },
    {
        title: 'Вывод средств',
        route: 'admin-withdrawal',
        icon: 'transactions',
        group: "admin"
    },
    {
        title: 'Партнерская программа',
        route: 'admin-referral',
        icon: 'referral',
        group: "admin"
    },
    // {
    //     title: 'Вакансии',
    //     route: 'admin-vacancies-list',
    //     icon: 'referral',
    //     group: "admin"
    // },
    // {
    //     title: 'База знаний',
    //     route: 'admin-knowledge-list',
    //     icon: 'referral',
    //     group: "admin"
    // },
    {
        title: "Контент",
        icon: 'database',
        route: 'admin-site-content',
        group: "admin",
        children: [
            {  route: 'admin-news-list', icon: 'sub', title: "Новости", group: "admin" },
            {  route: 'admin-vacancies-list', icon: 'sub', title: "Вакансии", group: "admin" },
            {  route: 'admin-knowledge-list', icon: 'sub', title: "База знаний", group: "admin" },
            
        ]
    },
    {
        title: 'Аналитика',
        route: 'admin-analytics',
        icon: 'statistic',
        group: "admin"
    },
    {
        title: 'Заявки',
        route: 'admin-orders',
        icon: 'transactions',
        group: "admin"
    },
    {
        title: "Настройки",
        icon: 'settings',
        route: 'admin-settings',
        group: "admin",
        children: [
            {  route: 'admin-system-settings', icon: 'sub', title: "Основное", group: "admin" },
            {  route: 'admin-group-perms', icon: 'sub', title: "Права групп пользователей", group: "admin" },
            
        ]
    }
]