
export default [
    {
        title: 'navigation.home',
        route: 'user-dashboard',
        icon: 'home',
        side: "bottom",
    },
    {
        title: 'navigation.cloud',
        icon: 'cloud',
        side: "right",
        visible: false,
        children: [
            { icon: 'sub', title: 'navigation.my-contracts', group: "user", route: "user-contracts-list" },
            { icon: 'sub', title: 'navigation.rent', group: "user", route: "user-contracts-create" },
            { icon: 'sub', title: 'navigation.statistics', group: "user", route: {
                name: "user-statistic-cloud",
                params: {
                    group: "cloud"
                }
            }},
        ]
    },
    {
        title: 'navigation.miners',
        icon: 'mining',
        side: "right",
        visible: false,
        children: [
            { icon: 'sub', title: 'navigation.my-miners', group: "user", route: "user-miners-list" },
            { icon: 'sub', title: 'navigation.buy', group: "user", route: "user-miners-catalog" },
            { icon: 'sub', title: 'navigation.statistics', group: "user", route: {
                name: "user-statistic-miners",
                params: {
                    group: "miners"
                }
            }},
        ]
    },
    {
        title: 'navigation.statistics',
        route: 'user-statistic-picker',
        icon: 'statistic',
        side: "bottom",
    },
    {
        title: 'navigation.create',
        icon: 'plus',
        side: "bottom",
        visible: false,
        route: "user-contracts-picker"
    },
    {
        title: 'navigation.wallet',
        route: 'user-wallet',
        icon: 'wallet',
        side: "bottom",
    },
    {
        title: 'navigation.orders',
        route: 'user-orders',
        icon: 'transactions',
        side: "right",
    },
    // {
    //     title: 'Транзакции',
    //     route: 'user-transactions',
    //     icon: 'transactions',
    //     side: "bottom",
    // },
    {
        title: 'navigation.calculator',
        route: 'user-calculator',
        icon: 'calculator',
        side: "right",
    },
    {
        title: 'navigation.news',
        route: 'user-news',
        icon: 'news',
        side: "right",
        group: "user"
    },
    {
        title: 'navigation.docs',
        route: 'user-documents',
        icon: 'documents',
        side: "right",
    },
    {
        title: 'navigation.affiliate-program',
        route: 'user-referral',
        icon: 'referral',
        side: "right",
    },
    {
        title: 'navigation.settings',
        route: 'user-account',
        icon: 'settings',
        side: "right",
    } 
]